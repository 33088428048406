import { useMediaQuery, useTheme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const useMediaQueryDown = (breakpoint: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

enum WeekdayFormats {
  Short = 'eee',
  Long = 'eeee',
}

export const useResponsiveWeekdayFormat = (): WeekdayFormats => {
  const isDown = useMediaQueryDown('sm');
  return isDown ? WeekdayFormats.Short : WeekdayFormats.Long;
};

export const SAMPLE_TICKERS = [
  'XOM',
  'GES',
  'IBM',
  'INTC',
  'T',
  'NVDA',
  'AAPL',
  'F',
  'GOOGL',
  'BAC',
  'MSFT',
  'DIS',
  'sbux',
  'met',
  'rl',
  'ma',
  'jpm',
  'ko',
  'o',
  'mcd',
  'atvi',
  'v',
  'pfe',
  'axp',
  'pm',
  'wfc',
  'mmm',
  'pep',
  'csco',
  'usb',
  'wmt',
  'abbv',
  'cvx',
  'pg',
  'JNJ',
  'vz',
  'gs',
  'ritm',
  'm',
  'nke',
  'nly',
  'so',
  'khc',
  'mrk',
  'vtrs',
  'ms',
  'ivr',
  'agnc',
  'kd',
  'sony',
  'amt',
  'dlr',
  'wbd',
];

import { RawStockData, StockData, StockInfo } from '../api';
import { Event } from 'react-big-calendar';
import { mapObjIndexed, pipe } from 'ramda';
import { toArray } from 'ramda-adjunct';
import { getStocksWithDividendDates, getValidStocks, pickDividendDate } from './stockData';

const transformToCalendarEvent = (data: StockData): Event[] =>
  pipe(
    mapObjIndexed((stock: StockInfo, ticker) => {
      const dividendDate = pickDividendDate(stock);

      return {
        title: ticker,
        allDay: true,
        start: dividendDate,
        end: dividendDate,
        resource: stock,
      };
    }),
    toArray
  )(data);

export const getCalendarDividendEvents = (data: RawStockData): Event[] =>
  pipe(getValidStocks, getStocksWithDividendDates, transformToCalendarEvent)(data);

import isBefore from 'date-fns/isBefore';
import { always, filter, has, hasPath, ifElse, keys, mapObjIndexed, path, pathSatisfies, pipe, reject, sortBy, values } from 'ramda';
import { RawStockData, StockData, StockInfo, StrCalendarEvents } from '../api';
import { isNonEmptyObj } from './general';

const DIVIDEND_DATE_PATH = ['calendarEvents', 'dividendDate'];

export type DividendDate = Pick<StrCalendarEvents, 'dividendDate'>;

export interface StocksDividendData {
  [key: string]: DividendDate;
}

export const getStocksWithDividendDates = (data: StockData): StockData => filter(hasPath(DIVIDEND_DATE_PATH), data);
export const getStocksWithoutDividendDates = (data: StockData): StockData => reject(hasPath(DIVIDEND_DATE_PATH), data);

export const getValidStocks = (data: RawStockData): StockData => reject(has('error'), data);
export const getInvalidStocks = (data: RawStockData): StockData => filter(has('error'), data);

export const getAndStringifyValidDividendStocks = (data: RawStockData): string =>
  pipe(
    getValidStocks,
    getStocksWithDividendDates,
    ifElse(isNonEmptyObj, (validStocks) => JSON.stringify(validStocks), always(''))
  )(data);

export const pickDividendDate = (data: StockInfo): DividendDate | undefined => path(DIVIDEND_DATE_PATH, data);

export const getStockTickers = (stocks: StockData) => keys(stocks);

const getStockShortName = (stock: StockInfo) => path(['price', 'shortName'], stock);
export const getStockTicker = (stock: StockInfo) => path<string>(['price', 'symbol'], stock);
export const getStockLabel = (stock: StockInfo, ticker?: string) => `${getStockShortName(stock)} (${ticker || getStockTicker(stock)})`;

export function mapStockData<T>(processFn: (stockInfo: StockInfo, ticker: string) => T, stocks: StockData): T[] {
  return pipe(mapObjIndexed(processFn), values)(stocks);
}

export function mapAndSortStockData<T>(
  processFn: (stockInfo: StockInfo, ticker: string) => T,
  sortFn: (processedData: T[]) => T[],
  stocks: StockData
): T[] {
  return pipe((stocks: StockData) => mapStockData(processFn, stocks), sortFn)(stocks);
}

export function sortStockDataByDividendDate(stocks: StockData): StockInfo[] {
  return pipe<StockData, StockInfo[], StockInfo[]>(
    values,
    // @ts-ignore
    sortBy(pickDividendDate)
    // @ts-ignore
  )(stocks);
}

export const getStockLabels = (stocks: StockData) => mapStockData(getStockLabel, stocks);

export const isPastDividendStock = (stock: StockInfo, compareDate = new Date()) =>
  pathSatisfies((dateStr: string) => isBefore(new Date(dateStr), compareDate), DIVIDEND_DATE_PATH, stock);

export const getStocksWithPastDividendDate = (data: StockData): StockData => filter(isPastDividendStock, data);

export const getStockCurrency = (stock: StockInfo): string | undefined => path(['price', 'currency'], stock);

import { useEffect, useMemo, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { Event } from 'react-big-calendar';
import { useBoolean } from 'use-boolean';
import { any, isEmpty } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import { getStockInfo, StockData } from '../api';
import { getCalendarDividendEvents } from '../helpers/calendar';
import { getAndStringifyValidDividendStocks, getStocksWithoutDividendDates, getStocksWithPastDividendDate } from '../helpers/stockData';

export const useStockData = (): UseStockData => {
  const [savedStocks, setSavedStocks] = useLocalStorage('savedStocks', '');
  const [stocks, setStocks] = useState<StockData>({});
  const [canUseSavedStocks, , disableUsingSavedStocks] = useBoolean(true);
  const [showingNonDivStocks, showNonDivStocks, hideNonDivStocks] = useBoolean(false);
  const [fetching, , , toggleFetching] = useBoolean(false);

  const dividendData = useMemo(() => getCalendarDividendEvents(stocks), [stocks]);
  const nonDividendStocks = useMemo(() => getStocksWithoutDividendDates(stocks), [stocks]);
  const pastDividendStocks = useMemo(() => getStocksWithPastDividendDate(stocks), [stocks]);

  const hasNonOrPastDividendStocks = useMemo(
    () => any(isNotEmpty)([nonDividendStocks, pastDividendStocks]),
    [nonDividendStocks, pastDividendStocks]
  );
  const useSavedStocks = isEmpty(stocks) && !isEmpty(savedStocks);

  useEffect(() => {
    if (canUseSavedStocks && useSavedStocks) {
      setStocks(JSON.parse(savedStocks));
    }
  }, [canUseSavedStocks, savedStocks, useSavedStocks]);

  useEffect(() => {
    if (hasNonOrPastDividendStocks && !canUseSavedStocks) {
      showNonDivStocks();
    }
  }, [stocks, canUseSavedStocks, hasNonOrPastDividendStocks, showNonDivStocks]);

  const handleTickerSubmit = async (tickers: string[], saveSearch: boolean) => {
    toggleFetching();
    const stockData = await getStockInfo(tickers);
    setStocks(stockData);
    disableUsingSavedStocks();
    if (saveSearch) {
      setSavedStocks(getAndStringifyValidDividendStocks(stockData));
    } else {
      setSavedStocks('');
    }
    toggleFetching();
  };

  return [
    { fetching, dividendData, showingNonDivStocks, nonDividendStocks, pastDividendStocks },
    { handleTickerSubmit, hideNonDivStocks },
  ];
};

interface Values {
  fetching: boolean;
  showingNonDivStocks: boolean;
  dividendData: Event[];
  nonDividendStocks: StockData;
  pastDividendStocks: StockData;
}

interface Controls {
  handleTickerSubmit(tickers: string[], saveSearch: boolean): Promise<void>;

  hideNonDivStocks(): void;
}

type UseStockData = [Values, Controls];

import { CalendarEvents, QuoteSummaryResult } from 'yahoo-finance2/dist/esm/src/modules/quoteSummary-iface';

export interface StrCalendarEvents {
  exDividendDate?: string; // yahoo-finance lib types dates as Date, but api sends strings
  dividendDate?: string;
}

export type StockInfo = Pick<Required<QuoteSummaryResult>, 'price' | 'summaryDetail' | 'defaultKeyStatistics'> & {
  calendarEvents: CalendarEvents | StrCalendarEvents;
};

type StockError = { error?: string };

export interface RawStockData {
  [key: string]: StockInfo & StockError;
}

export interface StockData {
  [key: string]: StockInfo;
}

export const getStockInfo = async (tickers: string[]): Promise<StockData> =>
  await (
    await fetch(`api/tickers`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tickers }),
    })
  ).json();

import { createStyles, List, ListItem, ListItemText, ListSubheader, makeStyles, Theme, Typography } from '@material-ui/core';
import { identity } from 'ramda';
import { ensureArray, isNotNil } from 'ramda-adjunct';
import classNames from 'classnames';

import { mapAndSortStockData } from '../../helpers/stockData';
import { decamelizeAndCapitalize, formatProperty, isNonEmptyObj, sortByKey } from '../../helpers/general';
import { useCurrency } from '../../hooks/useCurrency';

interface Props {
  data: { [key: string]: any };
  title: string;
  level?: number;
  sortLevels?: number | number[];
}

interface ThemeProps {
  level: number;
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    subheader: {
      lineHeight: 'normal',
    },
    subheaderText: {
      fontWeight: ({ level }: ThemeProps) => (level === 0 ? 600 : 400),
    },
    levelPadding: {
      paddingLeft: ({ level }: ThemeProps) => spacing(level * 1.5),
    },
    value: {
      fontWeight: 600,
      color: palette.primary.dark,
    },
    [breakpoints.down('xs')]: {
      levelPadding: {
        paddingLeft: ({ level }: ThemeProps) => spacing(level),
      },
    },
  })
);

export const PropList = ({ data, title, level = 0, sortLevels = [] }: Props) => {
  const classes = useStyles({ level });
  const currency = useCurrency();
  const currentLevelSortFn = ensureArray(sortLevels).includes(level) ? sortByKey : identity;

  return (
    <List>
      <ListSubheader disableSticky classes={{ root: classNames(classes.subheader, classes.levelPadding) }}>
        <Typography variant="overline" className={classes.subheaderText}>
          {title}
        </Typography>
      </ListSubheader>
      {mapAndSortStockData(
        (val, key) => {
          const normalizedKey = decamelizeAndCapitalize(key);
          const formattedVal = formatProperty({ key: normalizedKey, val }, currency);

          return isNonEmptyObj(val) ? (
            <PropList key={key} data={val} title={normalizedKey} level={level + 1} sortLevels={sortLevels} />
          ) : (
            isNotNil(val) && (
              <ListItem key={key}>
                <ListItemText className={classes.levelPadding}>
                  {normalizedKey}: <span className={classes.value}>{formattedVal}</span>
                </ListItemText>
              </ListItem>
            )
          );
        },
        currentLevelSortFn,
        data
      )}
    </List>
  );
};

import { PropertiesHyphenFallback as CSSProps } from 'csstype';
import { Palette, PaletteColor, TypeText } from '@material-ui/core/styles/createPalette';

const text: TypeText = {
  primary: '#101315',
  secondary: '#607D8B',
  disabled: '#B0BEC5',
  hint: '#f9a825',
};

export const colors: Pick<Palette, 'primary' | 'secondary' | 'text'> & { tertiary: PaletteColor } = {
  primary: {
    light: '#6abf69',
    main: '#388e3c',
    dark: '#00600f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffd95a',
    main: '#f9a825',
    dark: '#c17900',
    contrastText: text.primary,
  },

  tertiary: {
    light: '#50cdd7',
    main: '#388a8e',
    dark: '#2f6664',
    contrastText: '#fff',
  },
  text,
};

export const getTransition = (property: keyof CSSProps, ms = 200) => `${property} ${ms}ms ease-out`;

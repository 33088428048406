import styled from 'styled-components';
import { colors } from '../../styles/styleConstants';
import { theme } from '../../styles/theme';

export const Wrapper = styled.div`
  color: ${colors.text.primary};

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      margin-top: 1.5rem;
    }
  }
`;

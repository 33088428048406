import useLocalStorage from 'react-use-localstorage';
import { useEffect } from 'react';

interface SearchEvent extends Event {
  target: EventTarget & HTMLInputElement;
}

export const useTickersForm = (): UseTickersForm => {
  const [savedTickers, setSaveTickers] = useLocalStorage('userTickers', '');
  const [lastSearchTickers, setLastSearchTickers] = useLocalStorage('lastSearchTickers', '');
  const [saveSearch, setSaveSearch] = useLocalStorage('saveSearch', '');

  useEffect(() => {
    const handleSearchClear = ((e: SearchEvent) => setLastSearchTickers(e.target.value)) as EventListener;

    const searchInput = document.getElementById('searchInput');
    searchInput?.addEventListener('search', handleSearchClear);

    return () => searchInput?.removeEventListener('search', handleSearchClear);
  }, [setLastSearchTickers]);

  return [
    { savedTickers, lastSearchTickers, saveSearch: saveSearch === 'true' },
    { setSaveTickers, setLastSearchTickers, setSaveSearch: (saveSearch) => setSaveSearch(saveSearch.toString()) },
  ];
};

interface Values {
  savedTickers: string;
  lastSearchTickers: string;
  saveSearch: boolean;
}

interface Setters {
  setSaveTickers: (tickers: string) => void;
  setLastSearchTickers: (tickers: string) => void;
  setSaveSearch: (saveSearch: boolean) => void;
}

type UseTickersForm = [Values, Setters];

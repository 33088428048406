import { Field, Formik } from 'formik';
import React from 'react';
import { Wrapper } from './componentStyles/TickersForm.styles';
import { getSampleTickers, normalizeTickers } from '../helpers/tickers';
import { isEmpty } from 'ramda';
import { useTickersForm } from '../hooks/useTickersForm';

export interface Props {
  handleSubmit: (tickers: string[], saveSearch: boolean) => void;
}

interface FormData {
  tickers: string;
  saveTickers: boolean;
  saveSearch: boolean;
}

export const TickersForm: React.FC<Props> = ({ handleSubmit }: Props) => {
  const [values, setters] = useTickersForm();
  const { savedTickers, lastSearchTickers, saveSearch } = values;
  const { setSaveTickers, setLastSearchTickers, setSaveSearch } = setters;

  return (
    <Wrapper>
      <Formik
        initialValues={{ tickers: lastSearchTickers, saveTickers: false, saveSearch } as FormData}
        onSubmit={({ tickers, saveTickers, saveSearch }, { setSubmitting }) => {
          const normalizedTickers = normalizeTickers(tickers);
          const trimmedUserTickers = tickers.trim();
          if (saveTickers) {
            setSaveTickers(trimmedUserTickers);
          }
          setLastSearchTickers(trimmedUserTickers);
          setSaveSearch(saveSearch);
          handleSubmit(normalizedTickers, saveSearch);
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <fieldset>
              <label htmlFor="tickers">Tickers:</label>
              <input
                placeholder="Enter your stock tickers, comma separated"
                name="tickers"
                id="searchInput"
                type="search"
                required
                onChange={handleChange}
                value={values.tickers}
              />
              <label htmlFor="saveTickers" className="checkbox-label">
                <Field type="checkbox" name="saveTickers" id="saveTickers" />
                Save as my tickers
              </label>
              <label htmlFor="saveSearch" className="checkbox-label">
                <Field type="checkbox" name="saveSearch" id="saveSearch" />
                Save search data
              </label>
            </fieldset>
            <fieldset>
              <button type="submit" disabled={isSubmitting || !isValid}>
                Get Dividend Dates
              </button>
              {!isEmpty(savedTickers) && (
                <button type="button" className="user-tickers" onClick={() => setFieldValue('tickers', savedTickers)}>
                  Use my tickers
                </button>
              )}
              <button type="button" className="sample" onClick={() => setFieldValue('tickers', getSampleTickers())}>
                Sample tickers
              </button>
            </fieldset>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

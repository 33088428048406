import React from 'react';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LinearProgress, ThemeProvider } from '@material-ui/core';
import { DividendCalendar } from './components/Calendar/DividendCalendar';
import { Wrapper } from './components/componentStyles/App.styles';
import { TickersForm } from './components/TickersForm';
import { StockIssuesDialog } from './components/Dialogs/StockIssuesDialog';
import { theme } from './styles/theme';
import { WhatsNewDialog } from './components/Dialogs/WhatsNewDialog';
import { useStockData } from './hooks/useStockData';

const App = () => {
  const [values, controls] = useStockData();
  const { fetching, dividendData, showingNonDivStocks, nonDividendStocks, pastDividendStocks } = values;
  const { handleTickerSubmit, hideNonDivStocks } = controls;

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <header>
          <TickersForm handleSubmit={handleTickerSubmit} />
          <WhatsNewDialog />
        </header>
        {fetching && <LinearProgress color="primary" />}
        <DividendCalendar dividendData={dividendData} />
      </Wrapper>
      <StockIssuesDialog
        showDialog={showingNonDivStocks}
        onDialogClose={hideNonDivStocks}
        nonDividendStocks={nonDividendStocks}
        pastDividendStocks={pastDividendStocks}
      />
    </ThemeProvider>
  );
};

export default App;

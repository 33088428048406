import styled from 'styled-components';
import { colors, getTransition } from '../../styles/styleConstants';
import { theme } from '../../styles/theme';

export const Wrapper = styled.div`
  margin: 1rem;

  ${theme.breakpoints.down('xs')} {
    margin: 0.5rem;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  label {
    margin: 0.25rem;

    &.checkbox-label {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  fieldset {
    border: none;
    display: flex;
    align-items: center;
    flex-wrap: inherit;
    padding: 0;
    margin: 0.5rem;

    ${theme.breakpoints.down('xs')} {
      margin: 0.25rem;
    }
  }

  input {
    padding: 0.5rem;
    border-color: ${colors.primary.dark};
    border-radius: 4px;

    &[type='search'] {
      width: 300px;

      &::-webkit-search-cancel-button {
        cursor: pointer;
        padding: 0.1rem;
      }
    }

    &[type='checkbox'] {
      margin: 0.5rem;
    }

    &:focus {
      transition: ${getTransition('outline-color')};
      outline-color: ${colors.primary.light};
    }
  }

  button {
    cursor: pointer;
    padding: 0.5rem;
    font-weight: 600;
    background-color: ${colors.primary.main};
    border-radius: 4px;
    border-color: ${colors.primary.main};
    margin: 0.5rem;

    &:hover {
      transition: ${getTransition('color')};
      color: white;
    }

    &.sample {
      background-color: ${colors.secondary.main};
      border-color: ${colors.secondary.main};
    }

    &.user-tickers {
      background-color: ${colors.tertiary.main};
      border-color: ${colors.tertiary.main};
    }
  }
`;

import React from 'react';
import { Event, EventProps } from 'react-big-calendar';

interface IProps {
  handleClick: (event: Event) => void;
  eventProps: EventProps;
}

export const AGENDA_EVENT_CLASSNAME = 'agenda-event';

export const AgendaEvent: React.FC<IProps> = ({ eventProps: { event, title }, handleClick }: IProps) => (
  <div className={AGENDA_EVENT_CLASSNAME} onClick={() => handleClick(event)}>
    {title}
  </div>
);

import { isEmpty, map, pipe, reject, split, toUpper, trim } from 'ramda';
import { SAMPLE_TICKERS } from './constants';

export const normalizeTickers = (tickers: string) => pipe(toUpper, split(','), map(trim), reject(isEmpty))(tickers);

export const getSampleTickers = (tickers: string[] = SAMPLE_TICKERS) =>
  tickers
    .map((ticker) => ticker.toUpperCase())
    .sort()
    .join(',');

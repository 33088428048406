import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { isEmpty } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import { KeyboardEvent, useMemo } from 'react';

import { RawStockData, StockData } from '../../api';
import { getInvalidStocks, getStockLabels, getStockTickers, getValidStocks } from '../../helpers/stockData';
import { PastDividendStockInfo } from '../Lists/PastDividendStockInfo';

interface Props {
  showDialog: boolean;
  onDialogClose: () => void;
  nonDividendStocks: RawStockData;
  pastDividendStocks: StockData;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    listItem: {
      paddingLeft: spacing(2),
    },
  })
);

export const StockIssuesDialog = ({ showDialog, onDialogClose, nonDividendStocks, pastDividendStocks }: Props) => {
  const closeOnEnter = ({ key }: KeyboardEvent) => {
    if (key === 'Enter') {
      onDialogClose();
    }
  };
  const noDividendStocks = getValidStocks(nonDividendStocks);
  const invalidStocks = getInvalidStocks(nonDividendStocks);
  const noDividendStockLabels = useMemo(() => getStockLabels(noDividendStocks), [noDividendStocks]);

  const classes = useStyles();

  return (
    <Dialog open={showDialog} onClose={onDialogClose} onKeyPress={closeOnEnter}>
      <DialogTitle>
        {isEmpty(pastDividendStocks) ? 'Some of your stocks do not have dividends!' : 'Some of your stocks do not expect dividends (yet)!'}
      </DialogTitle>
      <DialogContent>
        <List>
          {isNotEmpty(pastDividendStocks) && (
            <>
              <ListSubheader disableSticky>Dividends with payout date in the past:</ListSubheader>
              <PastDividendStockInfo listItemClass={classes.listItem} pastDividendStocks={pastDividendStocks} />
            </>
          )}
          {isNotEmpty(noDividendStocks) && (
            <>
              <ListSubheader>Stocks without dividends:</ListSubheader>
              {noDividendStockLabels.map((stockLabel) => (
                <ListItemText key={stockLabel} className={classes.listItem}>
                  {stockLabel}
                </ListItemText>
              ))}
            </>
          )}
          {isNotEmpty(invalidStocks) && (
            <>
              <ListSubheader>No data found for these tickers:</ListSubheader>
              {getStockTickers(invalidStocks).map((ticker) => (
                <ListItemText key={ticker} className={classes.listItem}>
                  {ticker}
                </ListItemText>
              ))}
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={onDialogClose} fullWidth>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const formatToLocaleDateString = (dateStr: string) => new Date(dateStr).toLocaleDateString();
export const formatToLocaleDateTimeString = (dateStr: string) => new Date(dateStr).toLocaleString();

const currencyFormatter = (currency: string) => new Intl.NumberFormat(navigator.language, { style: 'currency', currency });
export const formatToCurrency = (value: number, currency?: string) => (currency ? currencyFormatter(currency).format(value) : value);

export const NBSP = '\xa0';
export const formatToPercentage = (value: number, convertToPercentage?: boolean) => {
  const percentageValue = convertToPercentage ? value * 100 : value;
  return `${percentageValue.toFixed(2)}${NBSP}%`;
};

export const numberFormatter = (num: number) => num.toLocaleString(navigator.language);

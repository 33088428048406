import decamelize from 'decamelize';
import { both, cond, pipe, prop, replace, sortBy, T, toString } from 'ramda';
import { isBoolean, isNotEmpty, isNumber, isObj } from 'ramda-adjunct';

import {
  formatToCurrency,
  formatToLocaleDateString,
  formatToLocaleDateTimeString,
  formatToPercentage,
  numberFormatter,
} from './formatters';

const capitalize = (str: string) => `${str[0].toUpperCase()}${str.slice(1)}`;
export const fixSAndP = replace(/Sand p/, 'S&P ');

export const decamelizeAndCapitalize = (str: string) =>
  pipe<string, string, string, string>((str) => decamelize(str, ' '), capitalize, fixSAndP)(str);

export const isNonEmptyObj = both(isObj, isNotEmpty);

const strEndsWith = (str: string, ending: string) => str.toLowerCase().endsWith(ending);

const isDateProp = (str: string) => strEndsWith(str, 'date');
const isTimeProp = (str: string) => strEndsWith(str, 'time');

const timestampEndMatcher = /\.\d{3}Z$/;
const isTimestamp = (val: unknown) => timestampEndMatcher.test(val as string);

const isPropInList = (propEndingsList: string[], str: string) => propEndingsList.some((propEnding) => strEndsWith(str, propEnding));

export const PRICE_PROP_ENDINGS = ['price', 'cap', 'high', 'low', 'open', 'close', 'average', 'rate', 'eps', 'bid', 'ask', 'value'];
const isPriceProp = (prop: string) => isPropInList(PRICE_PROP_ENDINGS, prop);

export const PERCENTAGE_PROP_ENDINGS = ['five year avg dividend yield'];
const isPercentageProp = (prop: string) => isPropInList(PERCENTAGE_PROP_ENDINGS, prop);

export const PERCENTAGE_TO_CONVERT_PROP_ENDINGS = ['dividend yield', 'payout ratio', 'week change'];
const isPercentageToConvertProp = (prop: string) => isPropInList(PERCENTAGE_TO_CONVERT_PROP_ENDINGS, prop);

export const formatProperty = (data: { key: string; val: any }, currency?: string) =>
  cond([
    [({ key }) => isDateProp(key), ({ val }) => formatToLocaleDateString(val)],
    [({ key }) => isTimeProp(key), ({ val }) => formatToLocaleDateTimeString(val)],
    [({ val }) => isTimestamp(val), ({ val }) => formatToLocaleDateTimeString(val)],
    [({ key }) => isPriceProp(key), ({ val }) => formatToCurrency(val, currency)],
    [({ key }) => isPercentageProp(key), ({ val }) => formatToPercentage(val)],
    [({ key }) => isPercentageToConvertProp(key), ({ val }) => formatToPercentage(val, true)],
    [({ val }) => isBoolean(val), ({ val }) => toString(val)],
    [({ val }) => isNumber(val), ({ val }) => numberFormatter(val)],
    [T, prop('val')],
  ])(data);

export const sortByKey = sortBy(prop('key'));

import { createStyles, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import { useMemo } from 'react';

import { StockData } from '../../api';
import { getStockLabel, getStockTicker, pickDividendDate, sortStockDataByDividendDate } from '../../helpers/stockData';
import { formatToLocaleDateString } from '../../helpers/formatters';

interface Props {
  listItemClass: string;
  pastDividendStocks: StockData;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    lastDivDate: {
      paddingLeft: spacing(2),
    },
  })
);

export const PastDividendStockInfo = ({ listItemClass, pastDividendStocks }: Props) => {
  const classes = useStyles();
  const sortedPastDividends = useMemo(() => sortStockDataByDividendDate(pastDividendStocks), [pastDividendStocks]);

  return (
    <>
      {sortedPastDividends.map((stock) => {
        const dividendDate = pickDividendDate(stock) as string;
        const ticker = getStockTicker(stock) as string;

        return (
          <ListItemText key={ticker} className={listItemClass}>
            {getStockLabel(stock, ticker)}
            <Typography color="textSecondary" className={classes.lastDivDate}>
              Last dividend date: {formatToLocaleDateString(dividendDate)}
            </Typography>
          </ListItemText>
        );
      })}
    </>
  );
};

import React, { useCallback, useState } from 'react';
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar';
import { useBoolean } from 'use-boolean';
import { enUS } from 'date-fns/locale';
import { format, getDay, startOfWeek } from 'date-fns';
import classNames from 'classnames';

import { getStockLabel } from '../../helpers/stockData';
import { Wrapper as CalendarWrapper } from '../componentStyles/DividendCalendar.styles';
import { StockInfoDialog } from '../Dialogs/StockInfoDialog';
import { AgendaEvent } from './AgendaEvent';
import { StockInfo } from '../../api';
import { useResponsiveWeekdayFormat } from '../../hooks/mediaQueryHooks';

const locales = { 'en-US': enUS };
const calendarLocalizer = dateFnsLocalizer({ format, startOfWeek, getDay, locales });

export interface CalendarProps {
  dividendData?: Required<Event[]>;
}

export const DividendCalendar: React.FC<CalendarProps> = ({ dividendData = [] }: CalendarProps) => {
  const [showingStockInfo, showStockInfo, hideStockInfo] = useBoolean(false);
  const [selectedStock, setSelectedStock] = useState<Required<StockInfo> | null>(null);
  const weekdayFormat = useResponsiveWeekdayFormat();

  const getTooltip = useCallback(({ title, resource }: Event) => getStockLabel(resource, title), []);
  const setEventClassName = useCallback(
    ({ resource }: Event) => ({ className: classNames('dividend-event', { selected: resource === selectedStock }) }),
    [selectedStock]
  );
  const handleSelectEvent = useCallback(
    ({ resource }: Event) => {
      showStockInfo();
      setSelectedStock(resource);
    },
    [showStockInfo, setSelectedStock]
  );

  return (
    <>
      <CalendarWrapper>
        <Calendar
          selected={selectedStock}
          localizer={calendarLocalizer}
          events={dividendData}
          tooltipAccessor={getTooltip}
          eventPropGetter={setEventClassName}
          components={{
            agenda: { event: (props) => <AgendaEvent eventProps={props} handleClick={handleSelectEvent} /> },
          }}
          onSelectEvent={handleSelectEvent}
          formats={{ weekdayFormat }}
        />
      </CalendarWrapper>
      {selectedStock && <StockInfoDialog showDialog={showingStockInfo} onClose={hideStockInfo} stock={selectedStock} />}
    </>
  );
};

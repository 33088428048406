import styled from 'styled-components';
import { colors, getTransition } from '../../styles/styleConstants';
import { AGENDA_EVENT_CLASSNAME } from '../Calendar/AgendaEvent';
import { theme } from '../../styles/theme';

export const Wrapper = styled.div`
  height: 80vh;
  margin: 2rem;

  ${theme.breakpoints.down('xs')} {
    margin: 1rem;
    height: 75vh;
  }

  .rbc-toolbar {
    button {
      cursor: pointer;
    }
  }

  .rbc-today {
    background-color: ${colors.primary.light}50;
  }

  .dividend-event {
    background-color: ${colors.primary.dark};
    outline-color: ${colors.primary.light};
    transition: ${getTransition('background-color')}, ${getTransition('color')}, ${getTransition('font-weight')};

    &.selected {
      background-color: ${colors.primary.main};
      color: ${colors.text.primary};
      font-weight: bold;
    }

    .rbc-agenda-event-cell {
      padding: 0; // to enable onclick on the whole event cell

      .${AGENDA_EVENT_CLASSNAME} {
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  SlideProps,
  Theme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useBoolean } from 'use-boolean';
import ReactMarkdown from 'react-markdown';
import useLocalStorage from 'react-use-localstorage';
import FiberNew from '@material-ui/icons/FiberNew';

import { version } from '../../../package.json';

interface ITransitionProps extends TransitionProps {
  direction?: SlideProps['direction'];
}

const DEFAULT_DIRECTION: SlideProps['direction'] = 'up';

const Transition = React.forwardRef((props: ITransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
  <Slide direction={props.direction} ref={ref} {...props} />
));

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    iconButton: {
      margin: spacing('auto', 2, 'auto', 'auto'),

      [breakpoints.down('xs')]: {
        position: 'absolute',
        margin: 0,
        top: spacing(0.5),
        right: spacing(0.5),
        padding: spacing(0.5),
      },
      [breakpoints.down(500)]: {
        top: spacing(0.75),
      },
    },
    title: {
      color: palette.primary.main,
    },
  })
);

const WHATS_NEW_TEXT = `
##### In latest version:
* **Save** searched stocks for your **next visit**.
* Minor styling enhancements.
##### From previous versions:
* **Key statistics** and **Summary detail** sections in **stock info** dialog.
* Your last **search tickers** are saved for your **next visit**.
* Open **stock info** by selecting your stock in the **agenda**.
* Open **stock info** by selecting your stock in the **calendar**.
`;

export const WhatsNewDialog: React.FC = () => {
  const [isOpen, open, close] = useBoolean(false);
  const [seenIn, setSeenIn] = useLocalStorage('seenWhatsNewIn', '');
  const [direction, setDirection] = useState<SlideProps['direction']>(DEFAULT_DIRECTION);

  const classes = useStyles();

  const handleButtonOpen = () => {
    setDirection('left');
    open();
  };

  const handleClose = () => {
    setSeenIn(version);
    close();
  };

  useEffect(() => {
    if (seenIn !== version) {
      setDirection(DEFAULT_DIRECTION);
      open();
    }
  }, [open, seenIn]);

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleButtonOpen}>
        <FiberNew />
      </IconButton>
      <Dialog
        open={isOpen}
        keepMounted
        TransitionComponent={Transition}
        TransitionProps={{ direction } as ITransitionProps}
        onClose={handleClose}
      >
        <DialogTitle className={classes.title}>What's new!</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <ReactMarkdown>{WHATS_NEW_TEXT}</ReactMarkdown>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} fullWidth color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

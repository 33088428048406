import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useMemo } from 'react';

import { StockInfo } from '../../api';
import { getStockCurrency, getStockLabel } from '../../helpers/stockData';
import { CurrencyProvider } from '../../hooks/useCurrency';
import { PropList } from '../Lists/PropList';

interface Props {
  showDialog: boolean;
  onClose: () => void;
  stock: StockInfo;
}

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: palette.grey[500],
    },
  })
);

export const StockInfoDialog = ({ showDialog, onClose, stock }: Props) => {
  const classes = useStyles();
  const currency = useMemo(() => getStockCurrency(stock), [stock]);

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <CurrencyProvider value={currency}>
        <DialogTitle>
          <Typography color={'primary'} variant={'h6'} component={'div'}>
            {getStockLabel(stock)}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PropList data={stock} title={'Stock info:'} sortLevels={0} />
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose} fullWidth>
            OK
          </Button>
        </DialogActions>
      </CurrencyProvider>
    </Dialog>
  );
};
